import styled, { css } from 'styled-components'

export const fullLoaderStyles = { height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }

export const genericLoaderStyles = css`
  height: 16px;
  width: 16px;
  margin-left: 8px;
`

export const buttonStyles = css`
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`

export const LoginFormContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
