import { useState, useEffect, useMemo } from 'react'
import { get } from 'lodash'
import getConfig from 'next/config'
import { login, selfWithInitialData } from '../../graphql'
import cookiesParser from '../../helpers/cookiesParser'
import logout from '../../helpers/logout'
import { useQuery, useMutation } from '@apollo/client'
import { LoginFormContainer, FormContainer, buttonStyles, fullLoaderStyles } from './styles'
import { Form, Image, Spacing, StateButton, Text, Loader } from 'influ-dms'
import { useRouter } from 'next/router'
import { setAnalyticsId } from '../../helpers'
import { useTranslate } from '~/hooks/useTranslate'
import { useLanguageContext } from '../../context/Language'
import Link from 'next/link'
import { RouterHelper } from '../../routing/routerHelper'

const { publicRuntimeConfig } = getConfig()
const brandLogo = publicRuntimeConfig.brandImage

const redirectionHandler = (self, token, router) => {
  const contact = get(self, 'contact', null)
  const brands = get(self, 'brands') || []
  const notContact = !contact

  if (brands.length > 0) {
    const unauthorizedUrl = JSON.parse(window.localStorage.getItem('unauthorizedUrl'))
    const lastUnauthorizedUrl = get(unauthorizedUrl, 'lastUnauthorizedUrl')
    if (lastUnauthorizedUrl) {
      window.localStorage.setItem('unauthorizedUrl', JSON.stringify({ loginSuccessPreviousUnauthorizedUrl: lastUnauthorizedUrl }))
      window.location.href = lastUnauthorizedUrl
    }
    const brand = brands.find(br => br.id === localStorage.getItem('brand_active')) || brands[0]
    const brandId = brand.id
    window.localStorage.setItem('brand_active', brandId)
    const products = brand.products
    const hasIRM = products.includes('irm')
    const hasCampaigns = products.includes('campaigns')
    const hasSearch = products.includes('search')
    const hasCampaignsV1 = products.includes('campaignsV1')
    const hasReportsV2 = products.includes('reportsV2')

    if (!hasIRM && !hasCampaigns && !hasSearch && !hasCampaignsV1 && !hasReportsV2) {
      router.push(RouterHelper.getRoute('noSubscription', { brandId }))
      return
    }
    router.push(RouterHelper.getRoute('home', { brandId }))
  } else if (brands.length <= 0 && contact?.hubspotSteps && !contact?.hubspotSteps?.finish && token) {
    router.push(RouterHelper.getRoute('newOrganization', { token }))
  } else if (brands.length <= 0) {
    router.push(RouterHelper.getRoute('noBrand'))
  } else if (notContact) {
    router.push(RouterHelper.getRoute('noBrand'))
  }
}

const LoginForm = (props) => {
  const [errors, setErrors] = useState(undefined)
  const [token, setToken] = useState(get(cookiesParser(typeof window !== 'undefined' && document), 'token', null))
  const [tokenLoading, setTokenLoading] = useState(true)
  const [emailError, setEmailError] = useState()
  const router = useRouter()
  const errorCode = get(router, 'query.errorCode')
  const { t } = useTranslate()
  const { locale } = useLanguageContext()

  const messages = useMemo(() => ({
    placeholder: t('commons.logIn.titleParagraph1'),
    forgotPass: t('commons.logIn.forgotPassword.link'),
    login: t('commons.button.login'),
    emailRequired: t('commons.logIn.email.input.helperText'),
    passwordRequired: t('commons.logIn.password.input.helperText'),
    invalidUser: t('commons.logIn.form.input.helperText.error.invalidUserOrPassword'),
    invalidPermissions: t('commons.logIn.form.input.helperText.error.invalidPermissions'),
    loading: t('commons.button.loading'),
    loginAttemptsExceed: t('commons.logIn.form.input.helperText.error.attempsExceeded'),
    userBlocked: t('commons.logIn.form.input.helperText.error.userBlocked'),
    forbiddenPage: t('commons.logIn.form.input.helperText.error.youDontHavePermission'),
    generalError: t('commons.logIn.form.input.helperText.error.generalError')
  }), [locale])

  const {
    data: dataSelf,
    loading: loadingSelf,
    error: errorSelf,
    refetch: refetchSelf
  } = useQuery(selfWithInitialData, { fetchPolicy: 'network-only', skip: !token })

  const [loginMutation, { loading: loginLoading }] = useMutation(login)

  useEffect(() => {
    const notLoading = !loadingSelf
    const notToken = !token
    const self = get(dataSelf, 'self') || undefined
    const selfLoaded = self !== undefined
    const notSelf = !selfLoaded
    const notError = !errorSelf
    if (dataSelf) setAnalyticsId(dataSelf)

    if (tokenLoading) {
      if (notLoading && notSelf) setTokenLoading(false)
      if (notToken) setTokenLoading(false)
    }

    if (notLoading && notError && selfLoaded) {
      redirectionHandler(self, token, router)
    }
  }, [errorSelf, loadingSelf, dataSelf, tokenLoading])

  useEffect(() => {
    if (token) refetchSelf()
  }, [token])

  useEffect(() => {
    if (errorCode === '423') setErrors(messages.userBlocked)
    if (errorCode === '403') setErrors(messages.forbiddenPage)
  }, [errors, errorCode])

  const onSubmit = (values) => {
    setErrors(undefined)
    const { email, password } = values
    const variables = { email, password }
    if (loginLoading) return
    if (!email) {
      setEmailError(messages.emailRequired)
      return
    }
    if (!password) {
      setErrors(messages.passwordRequired)
      return
    }

    loginMutation({ variables })
      .then(async (res) => {
        const token = res.data.login.token
        document.cookie = `token=${token}; path=/`
        setToken(token)
      })
      .catch((errors) => {
        logout({ loginRedirection: false })

        if (errors.message.includes('Network error') && !errors.message.includes('Received status code 503')) {
          router.push(RouterHelper.getRoute('networkError'))
          return
        }

        if (errors.message.includes('login attempts exceeded')) {
          setErrors(messages.loginAttemptsExceed)
        } else if (errors.message.includes('401')) {
          setErrors(messages.invalidUser)
        } else if (errors.message.includes('403')) {
          setErrors(messages.invalidPermissions)
        } else {
          setErrors(messages.generalError)
        }
      })
  }

  if (tokenLoading) {
    return (
      <div style={fullLoaderStyles}>
        <Loader name='loading-login' />
      </div>
    )
  }

  return (
    <LoginFormContainer data-cy='login'>
      <Image width='250px' alt='brand logo' src={brandLogo} />
      <Spacing size='16' />
      <Text size='14'>{messages.placeholder}</Text>
      <Spacing size='8' />
      <Form
        onSubmit={(evt) => onSubmit(evt)}
      >
        <FormContainer>
          <Form.InputText
            variant={(errors || emailError) && 'danger'}
            helperText={emailError}
            placeholder={t('commons.logIn.email.input.placerholder')}
            name='email'
            onChange={() => { setErrors(undefined); setEmailError(undefined) }}
          />
          <Spacing size='8' />
          <Form.InputText
            name='password'
            type='password'
            placeholder={t('commons.logIn.password.input.placerholder')}
            onChange={() => setErrors(undefined)}
            variant={errors && 'danger'}
            helperText={errors}
          />
          <Spacing size='8' />
          <Link href={ RouterHelper.getUrl('resetPassword')}>
            {messages.forgotPass}
          </Link>
          <Spacing size='8' />
          <StateButton
            name='loginSubmit'
            type='submit'
            isExpanded
            styles={buttonStyles}
            showLoader
            loading={loginLoading}
          >{messages.login}
          </StateButton>
        </FormContainer>
      </Form>
    </LoginFormContainer>
  )
}

export default LoginForm
