import { Component } from 'react'
import LoginForm from '../containers/login/LoginForm'

class Index extends Component {
  render () {
    return (
      <LoginForm />
    )
  }
}

export default Index
